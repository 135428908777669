
import { ThumbUpFill24, ThumbDownFill24 } from '@mango/icons';
import clsx from 'clsx';

export function StatusIcon({ success }: { success: boolean }) {
	const Icon = success ? ThumbUpFill24 : ThumbDownFill24;
	return (
		<div
			className={clsx(
				'flex h-10 w-10 items-center justify-center  rounded-lg sm:h-10 sm:w-10 md:h-20 md:w-20',
				success ? 'bg-success' : 'bg-issue',
			)}
		>
			<Icon fill="currentColor" className="text-white sm:text-2xl" />
		</div>
	);
}
