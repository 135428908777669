

import type {
	ManObjectResponse,
	ManMoveObject,
	ManObjectInfo,
	ManObjectData,
} from '../types/objects.js';
import { getObjectFields, getObjectId, getObjectType } from '../types/objects.js';

import type { Option } from '../types/option.js';
import { getOption } from '../types/option.js';
import type { CoinStruct } from '../types/coin.js';
import type { StructTag } from '../bcs/index.js';
import type { Infer } from 'superstruct';
import { nullable, number, object, string } from 'superstruct';
import { normalizeManObjectId } from '../utils/man-types';

export const MAN_SYSTEM_ADDRESS = '0x3';
export const MAN_FRAMEWORK_ADDRESS = '0x2';
export const MOVE_STDLIB_ADDRESS = '0x1';
export const OBJECT_MODULE_NAME = 'object';
export const UID_STRUCT_NAME = 'UID';
export const ID_STRUCT_NAME = 'ID';
export const MAN_TYPE_ARG = `${MAN_FRAMEWORK_ADDRESS}::man::MAN`;
export const VALIDATORS_EVENTS_QUERY = '0x3::validator_set::ValidatorEpochInfoEventV2';

export const MAN_CLOCK_OBJECT_ID = normalizeManObjectId('0x6');

// `man::pay` module is used for Coin management (split, join, join_and_transfer etc);
export const PAY_MODULE_NAME = 'pay';
export const PAY_SPLIT_COIN_VEC_FUNC_NAME = 'split_vec';
export const PAY_JOIN_COIN_FUNC_NAME = 'join';
export const COIN_TYPE_ARG_REGEX = /^0x2::coin::Coin<(.+)>$/;

type ObjectData = ObjectDataFull | ManObjectInfo;
type ObjectDataFull = ManObjectResponse | ManMoveObject;

export function isObjectDataFull(resp: ObjectData | ObjectDataFull): resp is ManObjectResponse {
	return !!(resp as ManObjectResponse).data || !!(resp as ManMoveObject).type;
}

export const CoinMetadataStruct = object({
	decimals: number(),
	name: string(),
	symbol: string(),
	description: string(),
	iconUrl: nullable(string()),
	id: nullable(string()),
});

export type CoinMetadata = Infer<typeof CoinMetadataStruct>;

export class Coin {
	static isCoin(data: ObjectData): boolean {
		return Coin.getType(data)?.match(COIN_TYPE_ARG_REGEX) != null;
	}

	static getCoinType(type: string) {
		const [, res] = type.match(COIN_TYPE_ARG_REGEX) ?? [];
		return res || null;
	}

	static getCoinTypeArg(obj: ObjectData) {
		const type = Coin.getType(obj);
		return type ? Coin.getCoinType(type) : null;
	}

	static isMAN(obj: ObjectData) {
		const arg = Coin.getCoinTypeArg(obj);
		return arg ? Coin.getCoinSymbol(arg) === 'MAN' : false;
	}

	static getCoinSymbol(coinTypeArg: string) {
		return coinTypeArg.substring(coinTypeArg.lastIndexOf(':') + 1);
	}

	static getCoinStructTag(coinTypeArg: string): StructTag {
		return {
			address: normalizeManObjectId(coinTypeArg.split('::')[0]),
			module: coinTypeArg.split('::')[1],
			name: coinTypeArg.split('::')[2],
			typeParams: [],
		};
	}

	public static getID(obj: ObjectData): string {
		if ('fields' in obj) {
			return obj.fields.id.id;
		}
		return getObjectId(obj);
	}

	static totalBalance(coins: CoinStruct[]): bigint {
		return coins.reduce(
			(partialSum, c) => partialSum + Coin.getBalanceFromCoinStruct(c),
			BigInt(0),
		);
	}

	/**
	 * Sort coin by balance in an ascending order
	 */
	static sortByBalance(coins: CoinStruct[]): CoinStruct[] {
		return [...coins].sort((a, b) =>
			Coin.getBalanceFromCoinStruct(a) < Coin.getBalanceFromCoinStruct(b)
				? -1
				: Coin.getBalanceFromCoinStruct(a) > Coin.getBalanceFromCoinStruct(b)
				? 1
				: 0,
		);
	}

	static getBalanceFromCoinStruct(coin: CoinStruct): bigint {
		return BigInt(coin.balance);
	}

	static getBalance(data: ObjectDataFull): bigint | undefined {
		if (!Coin.isCoin(data)) {
			return undefined;
		}
		const balance = getObjectFields(data)?.balance;
		return BigInt(balance);
	}

	private static getType(data: ObjectData): string | null | undefined {
		if (isObjectDataFull(data)) {
			return getObjectType(data);
		}
		return data.type;
	}
}

export type DelegationData = ManMoveObject & {
	dataType: 'moveObject';
	type: '0x2::delegation::Delegation';
	fields: {
		active_delegation: Option<number>;
		delegate_amount: number;
		next_reward_unclaimed_epoch: number;
		validator_address: string;
		info: {
			id: string;
			version: number;
		};
		ending_epoch: Option<number>;
	};
};

export type DelegationManObject = Omit<ManObjectData, 'data'> & {
	data: DelegationData;
};

export class Delegation {
	public static readonly MAN_OBJECT_TYPE = '0x2::delegation::Delegation';
	private manObject: DelegationManObject;

	public static isDelegationManObject(obj: ManObjectData): obj is DelegationManObject {
		return 'type' in obj && obj.type === Delegation.MAN_OBJECT_TYPE;
	}

	constructor(obj: DelegationManObject) {
		this.manObject = obj;
	}

	public nextRewardUnclaimedEpoch() {
		return this.manObject.data.fields.next_reward_unclaimed_epoch;
	}

	public activeDelegation() {
		return BigInt(getOption(this.manObject.data.fields.active_delegation) || 0);
	}

	public delegateAmount() {
		return this.manObject.data.fields.delegate_amount;
	}

	public endingEpoch() {
		return getOption(this.manObject.data.fields.ending_epoch);
	}

	public validatorAddress() {
		return this.manObject.data.fields.validator_address;
	}

	public isActive() {
		return this.activeDelegation() > 0 && !this.endingEpoch();
	}

	public hasUnclaimedRewards(epoch: number) {
		return (
			this.nextRewardUnclaimedEpoch() <= epoch &&
			(this.isActive() || (this.endingEpoch() || 0) > epoch)
		);
	}
}
