
import { SVGProps } from 'react';
const SvgSearch16 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 16 16"
		{...props}
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M7.333 3.917a3.417 3.417 0 1 0 0 6.833 3.417 3.417 0 0 0 0-6.833ZM2.417 7.333A4.917 4.917 0 1 1 11.3 10.24l2.064 2.064a.75.75 0 0 1-1.061 1.06L10.239 11.3a4.917 4.917 0 0 1-7.822-3.966Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgSearch16;
