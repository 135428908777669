
import { SVGProps } from 'react';
const SvgPaginationNext24 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			stroke="currentColor"
			d="m9.479 6.807 7.912 4.315a1 1 0 0 1 0 1.756l-7.912 4.315A1 1 0 0 1 8 16.315v-8.63a1 1 0 0 1 1.479-.878Z"
		/>
	</svg>
);
export default SvgPaginationNext24;
