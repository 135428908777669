
import { SVGProps } from 'react';
const SvgThumbUpFill24 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="currentColor"
			d="M8 14.188c-.008 2.976 2.344 5.242 6.086 5.25h1.086c1.023 0 1.805-.079 2.258-.196.672-.164 1.273-.586 1.273-1.36a1.42 1.42 0 0 0-.172-.695c-.054-.085-.039-.164.04-.195.515-.234.906-.71.906-1.336 0-.36-.102-.68-.274-.906-.078-.11-.062-.203.063-.273.359-.227.609-.688.609-1.211 0-.383-.117-.782-.32-.985-.11-.101-.094-.172.023-.265.25-.227.406-.602.406-1.055 0-.805-.632-1.461-1.46-1.461h-2.82c-.68 0-1.126-.344-1.126-.898 0-1.055 1.336-3.024 1.336-4.454 0-.773-.492-1.242-1.164-1.242-.586 0-.89.383-1.219 1.031-1.195 2.305-2.773 4.18-3.984 5.79-1.055 1.39-1.54 2.585-1.547 4.46Zm-3.984.062c0 2.422 1.507 4.422 3.515 4.422h1.36c-1.414-1.078-2.04-2.68-2.024-4.5.008-1.969.711-3.399 1.453-4.375H7.234c-1.82 0-3.218 1.953-3.218 4.453Z"
		/>
	</svg>
);
export default SvgThumbUpFill24;
