

import { useFormatCoin } from '@mango/core';
import { type CoinStruct } from '@mango/man.js/client';
import { Text } from '@mango/ui';

import { ObjectLink } from '~/ui/InternalLink';

type CoinItemProps = {
	coin: CoinStruct;
};

export default function CoinItem({ coin }: CoinItemProps) {
	const [formattedBalance, symbol] = useFormatCoin(coin.balance, coin.coinType);
	return (
		<div className="defined-bg-black flex items-center justify-between rounded-lg px-3 py-2 shadow-panel">
			<ObjectLink objectId={coin.coinObjectId} className="defined-coin-text" />
			<div className="col-span-3 inline-flex items-center gap-1">
				<Text color="steel-darker" variant="bodySmall/medium">
					{formattedBalance}
				</Text>
				<Text color="defined-field-color" variant="subtitleSmallExtra/normal">
					{symbol}
				</Text>
			</div>
		</div>
	);
}
