

import { COIN_GECKO_MAN_URL, useManCoinData } from '@mango/core';
import { CoinLogo } from '@mango/icons';
import { Text } from '@mango/ui';

import { Card } from '~/ui/Card';
import { ButtonOrLink } from '~/ui/utils/ButtonOrLink';

export function ManTokenCard() {
	const { data } = useManCoinData();
	const { currentPrice } = data || {};

	const formattedPrice = currentPrice
		? currentPrice.toLocaleString('en', {
				style: 'currency',
				currency: 'USD',
		  })
		: '--';

	return (
		<ButtonOrLink target="_self" href="Javascript:()=>{};">
			<Card growOnHover bg="definedBlack" spacing="lg" height="full">
				<div className="flex items-center gap-2">
					<div className="flex w-full flex-col gap-0.5">
						<CoinLogo style={{ marginBottom: '20px' }} />
						<Text variant="body/semibold" color="steel">
							1 MAN = {formattedPrice}
						</Text>
						<div style={{ marginBottom: '10px' }} />
						<Text variant="subtitleSmallExtra/medium" color="steel-darker">
							via CoinGecko
						</Text>
					</div>
				</div>
			</Card>
		</ButtonOrLink>
	);
}
