
import { SVGProps } from 'react';
const SvgThumbDownFill24 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="currentColor"
			d="M16.023 10.375c.047-2.977-2.367-5.203-6.109-5.234l-1.086-.008c-1.023-.008-1.805.07-2.258.187-.672.164-1.273.586-1.273 1.36 0 .297.078.523.172.695.054.086.039.164-.04.195-.515.235-.906.711-.906 1.336 0 .36.102.68.274.906.078.11.062.204-.063.274-.359.226-.609.687-.609 1.21 0 .384.117.782.32.985.11.102.094.172-.015.274-.258.218-.414.593-.414 1.047 0 .804.632 1.46 1.46 1.46h2.82c.68 0 1.126.344 1.126.899 0 1.055-1.336 3.023-1.336 4.453 0 .773.5 1.242 1.164 1.242.586 0 .89-.383 1.219-1.031 1.195-2.305 2.773-4.18 3.984-5.79 1.055-1.39 1.54-2.585 1.57-4.46Zm3.946-.063c0-2.421-1.5-4.421-3.516-4.421h-1.351c1.406 1.078 2.07 2.68 2.046 4.5-.03 1.968-.734 3.398-1.476 4.375h1.078c1.82 0 3.219-1.954 3.219-4.454Z"
		/>
	</svg>
);
export default SvgThumbDownFill24;
