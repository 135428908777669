

import type { UseManClientQueryOptions } from './useManClientQuery';
import { useManClientQuery } from './useManClientQuery';

export function useRpcApiVersion(options?: UseManClientQueryOptions<'getRpcApiVersion'>) {
	return useManClientQuery(
		{
			method: 'getRpcApiVersion',
			params: {},
		},
		options,
	);
}
