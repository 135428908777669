

import { ManObjectData, ManObjectResponse } from '@mango/man.js/client';
import { KIOSK_OWNER_CAP } from '@mango/kiosk';

export const ORIGINBYTE_KIOSK_MODULE =
	'0x95a441d389b07437d00dd07e0b6f05f513d7659b13fd7c5d3923c7d9d847199b::ob_kiosk';

export const ORIGINBYTE_KIOSK_OWNER_TOKEN = `${ORIGINBYTE_KIOSK_MODULE}::OwnerToken`;

export function isKioskOwnerToken(object?: ManObjectResponse | ManObjectData | null) {
	if (!object) return false;
	const objectData = 'data' in object && object.data ? object.data : (object as ManObjectData);
	return [KIOSK_OWNER_CAP, ORIGINBYTE_KIOSK_OWNER_TOKEN].includes(objectData?.type ?? '');
}

export function getKioskIdFromOwnerCap(object: ManObjectResponse | ManObjectData) {
	const objectData = 'data' in object && object.data ? object.data : (object as ManObjectData);
	const fields =
		objectData.content?.dataType === 'moveObject'
			? (objectData.content.fields as { for?: string; kiosk?: string })
			: null;
	return fields?.for ?? fields?.kiosk!;
}
