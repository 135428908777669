

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Button } from '@mango/ui';
import { ConnectButton, useWalletKit, type WalletWithFeatures } from '@mango/wallet-kit';
import { useParams } from 'react-router-dom';

import { useTheme } from '~/context';

// This is a custom feature supported by the Man Wallet:
type StakeInput = { validatorAddress: string };
type ManWalletStakeFeature = {
	'manWallet:stake': {
		version: '0.0.1';
		stake: (input: StakeInput) => Promise<void>;
	};
};

type StakeWallet = WalletWithFeatures<Partial<ManWalletStakeFeature>>;

export function StakeButton() {
	const [localTheme] = useTheme();
	const stakeButtonEnabled = useFeatureIsOn('validator-page-staking');
	console.log('stakeButtonEnabled', stakeButtonEnabled);
	const { id } = useParams();
	const { wallets, currentWallet, connect } = useWalletKit();
	// if (!stakeButtonEnabled) return null;
	// @ts-ignore
	const stakeSupportedWallets = wallets.filter((wallet) => 'manWallet:stake' in wallet.features);
	const currentWalletSupportsStake =
		// @ts-ignore
		currentWallet && !!stakeSupportedWallets.find(({ name }) => currentWallet.name === name);
	if (!stakeSupportedWallets.length) {
		return (
			<Button size="lg" variant="definedBtn" asChild attr-id="defined-stake-btn">
				<a href="#" target="_blank" rel="noreferrer noopener">
					Install Man Wallet to stake MAN
				</a>
			</Button>
		);
	}

	if (!currentWallet) {
		return (
			<ConnectButton
				attr-id="defined-stake-btn"
				theme={localTheme}
				className="!border !border-solid !border-steel-dark !bg-transparent !px-4 !py-3 !text-body !font-semibold !text-steel-dark !shadow-none"
				connectText="Stake MAN"
			/>
		);
	}

	if (!currentWalletSupportsStake) {
		return (
			<Button
				attr-id="defined-stake-btn"
				variant="stakeStyle"
				size="lg"
				onClick={() => {
					// Always just assume we should connect to the first stake supported wallet for now:
					connect(stakeSupportedWallets[0].name);
				}}
			>
				Stake MAN on a supported wallet
			</Button>
		);
	}

	return (
		<Button
			size="lg"
			attr-id="defined-stake-btn"
			variant="stakeStyle"
			onClick={() => {
				(currentWallet as StakeWallet).features['manWallet:stake']?.stake({
					validatorAddress: id!,
				});
			}}
		>
			Stake MAN
		</Button>
	);
}
