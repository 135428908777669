

/**
 *  ######################################
 *  ### DO NOT EDIT THIS FILE DIRECTLY ###
 *  ######################################
 *
 * This file is generated from:
 * /crates/man-open-rpc/spec/openrpc.json
 */

import type { GetAllBalancesParams } from '@mango/man.js/client';
import type { UseManClientQueryOptions } from '../useManClientQuery';
import { useManClientQuery } from '../useManClientQuery';

export function useAllBalances(
	params: GetAllBalancesParams,
	options?: UseManClientQueryOptions<'getAllBalances'>,
) {
	return useManClientQuery(
		{
			method: 'getAllBalances',
			params,
		},
		options,
	);
}
