

import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useManClientContext } from './useManClient';
import type { ManClient } from '@mango/man.js/client';

export type ManRpcMethodName = {
	[K in keyof ManClient]: ManClient[K] extends ((input: any) => Promise<any>) | (() => Promise<any>)
		? K
		: never;
}[keyof ManClient];

export type ManRpcMethods = {
	[K in ManRpcMethodName]: ManClient[K] extends (input: infer P) => Promise<infer R>
		? {
				name: K;
				result: R;
				params: P;
		  }
		: ManClient[K] extends () => Promise<infer R>
		? {
				name: K;
				result: R;
				params: undefined;
		  }
		: never;
};

export type UseManClientQueryOptions<T extends keyof ManRpcMethods> = Omit<
	UseQueryOptions<ManRpcMethods[T]['result'], Error, ManRpcMethods[T]['result'], unknown[]>,
	'queryFn'
>;

export function useManClientQuery<T extends keyof ManRpcMethods>(
	{
		method,
		params,
	}: {
		method: T;
		params: ManRpcMethods[T]['params'];
	},
	{ queryKey, enabled = !!params, ...options }: UseManClientQueryOptions<T> = {},
) {
	const manContext = useManClientContext();

	return useQuery({
		...options,
		queryKey: [manContext.network, method, params],
		enabled,
		queryFn: async () => {
			return await manContext.client[method](params as never);
		},
	});
}
