

import { useManClient } from '@mango/dapp-kit';
import { type ManClient } from '@mango/man.js/client';
import { useQuery } from '@tanstack/react-query';

export function useGetAllEpochAddressMetrics(
	...input: Parameters<ManClient['getAllEpochAddressMetrics']>
) {
	const client = useManClient();
	return useQuery({
		queryKey: ['get', 'all', 'epoch', 'addresses', ...input],
		queryFn: () => client.getAllEpochAddressMetrics(...input),
	});
}
