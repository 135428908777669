

import { SentryHttpTransport } from '@mango/core';
import { ManClient, ManHTTPTransport, getFullnodeUrl } from '@mango/man.js/client';

export enum Network {
	LOCAL = 'LOCAL',
	MAINNET = 'MAINNET',
}

export enum Theme {
	Light = 'light',
	Dark = 'dark',
}

export const NetworkConfigs: Record<Network, { url: string }> = {
	[Network.LOCAL]: { url: getFullnodeUrl('localnet') },
	[Network.MAINNET]: { url: 'https://dev2-node.mangodemo.com' },
};

const defaultClientMap: Map<Network | string, ManClient> = new Map();

// NOTE: This class should not be used directly in React components, prefer to use the useManClient() hook instead
export const createManClient = (network: Network | string) => {
	const existingClient = defaultClientMap.get(network);
	if (existingClient) return existingClient;

	const networkUrl = network in Network ? NetworkConfigs[network as Network].url : network;

	const client = new ManClient({
		transport:
			network in Network && network === Network.MAINNET
				? new SentryHttpTransport(networkUrl)
				: new ManHTTPTransport({ url: networkUrl }),
	});
	defaultClientMap.set(network, client);
	return client;
};
