

/** Man Devnet */
export const MAN_DEVNET_CHAIN = 'man:devnet';

/** Man Testnet */
export const MAN_TESTNET_CHAIN = 'man:testnet';

/** Man Localnet */
export const MAN_LOCALNET_CHAIN = 'man:localnet';

/** Man Mainnet */
export const MAN_MAINNET_CHAIN = 'man:mainnet';

export const MAN_CHAINS = [
	MAN_DEVNET_CHAIN,
	MAN_TESTNET_CHAIN,
	MAN_LOCALNET_CHAIN,
	MAN_MAINNET_CHAIN,
] as const;

export type ManChain =
	| typeof MAN_DEVNET_CHAIN
	| typeof MAN_TESTNET_CHAIN
	| typeof MAN_LOCALNET_CHAIN
	| typeof MAN_MAINNET_CHAIN;
