

import { type ManEvent } from '@mango/man.js/client';

export function getValidatorMoveEvent(validatorsEvent: ManEvent[], validatorAddress: string) {
	const event = validatorsEvent.find(
		({ parsedJson }) =>
			(parsedJson as { validator_address?: unknown })!.validator_address === validatorAddress,
	);

	return event && event.parsedJson;
}
