

import { ArrowUpRight12 } from '@mango/icons';
import { type ManValidatorSummary } from '@mango/man.js/client';
import { Heading, Text } from '@mango/ui';

import { StakeButton } from './StakeButton';
import { CopyToClipboard } from '~/ui/CopyToClipboard';
import { DescriptionList, DescriptionItem } from '~/ui/DescriptionList';
import { ImageIcon } from '~/ui/ImageIcon';
import { AddressLink } from '~/ui/InternalLink';

type ValidatorMetaProps = {
	validatorData: ManValidatorSummary;
};

export function ValidatorMeta({ validatorData }: ValidatorMetaProps) {
	const validatorPublicKey = validatorData.protocolPubkeyBytes;
	const validatorName = validatorData.name;
	const logo = validatorData.imageUrl;
	const description = validatorData.description;
	const projectUrl = validatorData.projectUrl;
	return (
		<>
			<div
				className="flex basis-full gap-5 border-r border-transparent border-r-gray-45 md:mr-7.5 md:basis-1/3"
				style={{ alignItems: 'center' }}
			>
				<div className="flex-shrink-0">
					<ImageIcon src={logo} label={validatorName} fallback={validatorName} size="xl" />
				</div>
				<div className="mt-1.5 flex flex-col">
					<Heading as="h1" variant="heading2/bold" color="defined-node-title">
						{validatorName}
					</Heading>
					{projectUrl && (
						<a
							href={projectUrl}
							target="_blank"
							rel="noreferrer noopener"
							className="mt-2.5 inline-flex items-center gap-1.5 text-body font-medium text-man-dark no-underline "
						>
							{projectUrl.replace(/\/$/, '')}
							<ArrowUpRight12 className="text-steel" />
						</a>
					)}
					<div className="defined-stake-btn-container mt-3.5">
						<StakeButton />
					</div>
				</div>
			</div>
			<div className="min-w-0 basis-full break-words md:basis-2/3">
				<DescriptionList>
					<DescriptionItem
						title={
							<Text variant="pBody/normal" color="defined-node-label">
								Description
							</Text>
						}
						align="start"
					>
						<Text variant="pBody/normal" color="defined-node-text">
							{description || '--'}
						</Text>
					</DescriptionItem>
					<DescriptionItem
						title={
							<Text variant="pBody/normal" color="defined-node-label">
								Location
							</Text>
						}
						align="start"
					>
						<Text variant="pBody/normal" color="defined-node-text">
							--
						</Text>
					</DescriptionItem>
					<DescriptionItem
						title={
							<Text variant="pBody/normal" color="defined-node-label">
								Pool ID
							</Text>
						}
						align="start"
					>
						<div className="flex items-start gap-1 break-all">
							<Text variant="pBody/normal" color="defined-node-text">
								{validatorData.stakingPoolId}
							</Text>
							<CopyToClipboard size="sm" color="defined" copyText={validatorData.stakingPoolId} />
						</div>
					</DescriptionItem>
					<DescriptionItem
						title={
							<Text variant="pBody/normal" color="defined-node-label">
								Address
							</Text>
						}
						align="start"
					>
						<div className="flex items-start gap-1">
							<AddressLink variant="text2" address={validatorData.suiAddress} noTruncate />
							<CopyToClipboard size="sm" color="defined" copyText={validatorData.suiAddress} />
						</div>
					</DescriptionItem>
					<DescriptionItem
						title={
							<Text variant="pBody/normal" color="defined-node-label">
								Public Key
							</Text>
						}
						align="start"
					>
						<Text variant="pBody/normal" color="defined-node-text">
							{validatorPublicKey}
						</Text>
					</DescriptionItem>
				</DescriptionList>
			</div>
		</>
	);
}
