
import { SVGProps } from 'react';
const SvgCopy16 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 16 16"
		{...props}
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M6.5 2.25A2.25 2.25 0 0 0 4.25 4.5h1.5a.75.75 0 0 1 .75-.75H11c.69 0 1.25.56 1.25 1.25v4.5a.75.75 0 0 1-.75.75v1.5a2.25 2.25 0 0 0 2.25-2.25V5A2.75 2.75 0 0 0 11 2.25H6.5ZM3.5 7a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V7ZM2 7a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgCopy16;
