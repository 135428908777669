

import { useContext } from 'react';
import { ManClientContext } from '../components/ManClientProvider';

export function useManClientContext() {
	const manClient = useContext(ManClientContext);

	if (!manClient) {
		throw new Error(
			'Could not find ManClientContext. Ensure that you have set up the ManClientProvider',
		);
	}

	return manClient;
}

export function useManClient() {
	return useManClientContext().client;
}
