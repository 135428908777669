

import { useAllBalances } from '@mango/dapp-kit';
import { Info16 } from '@mango/icons';
import { Coin } from '@mango/man.js';
import { type CoinBalance } from '@mango/man.js/client';
import { normalizeManAddress } from '@mango/man.js/utils';
import { Heading, Text, LoadingIndicator, RadioGroup, RadioGroupItem } from '@mango/ui';
import clsx from 'clsx';
import { useMemo, useState } from 'react';

import OwnedCoinView from './OwnedCoinView';
import { useTheme } from '~/context';
import { useRecognizedPackages } from '~/hooks/useRecognizedPackages';
import { Pagination } from '~/ui/Pagination';
import { TabsList, TabsTrigger } from '~/ui/Tabs';

export type CoinBalanceVerified = CoinBalance & {
	isRecognized?: boolean;
};

enum COIN_FILTERS {
	ALL = 'allBalances',
	RECOGNIZED = 'recognizedBalances',
	UNRECOGNIZED = 'unrecognizedBalances',
}

export function OwnedCoins({ id }: { id: string }) {
	const [localTheme] = useTheme();
	const [currentSlice, setCurrentSlice] = useState(1);
	const [limit, setLimit] = useState(20);
	const [filterValue, setFilterValue] = useState(COIN_FILTERS.RECOGNIZED);
	const { isLoading, data, isError } = useAllBalances({ owner: normalizeManAddress(id) });
	const recognizedPackages = useRecognizedPackages();

	const balances: Record<COIN_FILTERS, CoinBalanceVerified[]> = useMemo(() => {
		const balanceData = data?.reduce(
			(acc, coinBalance) => {
				if (recognizedPackages.includes(coinBalance.coinType.split('::')[0])) {
					acc.recognizedBalances.push({
						...coinBalance,
						isRecognized: true,
					});
				} else {
					acc.unrecognizedBalances.push({ ...coinBalance, isRecognized: false });
				}
				return acc;
			},
			{
				recognizedBalances: [] as CoinBalanceVerified[],
				unrecognizedBalances: [] as CoinBalanceVerified[],
			},
		) ?? { recognizedBalances: [], unrecognizedBalances: [] };

		const recognizedBalances = balanceData.recognizedBalances.sort((a, b) => {
			// Make sure MAN always comes first
			if (Coin.getCoinSymbol(a.coinType) === 'MAN') {
				return -1;
			} else if (Coin.getCoinSymbol(b.coinType) === 'MAN') {
				return 1;
			} else {
				return Coin.getCoinSymbol(a.coinType).localeCompare(
					Coin.getCoinSymbol(b.coinType),
					undefined,
					{ sensitivity: 'base' },
				);
			}
		});

		return {
			recognizedBalances,
			unrecognizedBalances: balanceData.unrecognizedBalances.sort((a, b) =>
				Coin.getCoinSymbol(a.coinType).localeCompare(Coin.getCoinSymbol(b.coinType), undefined, {
					sensitivity: 'base',
				}),
			),
			allBalances: balanceData.recognizedBalances.concat(balanceData.unrecognizedBalances),
		};
	}, [data, recognizedPackages]);

	const filterOptions = useMemo(
		() => [
			{ label: `${balances.recognizedBalances.length} RECOGNIZED`, value: COIN_FILTERS.RECOGNIZED },
			{
				label: `${balances.unrecognizedBalances.length} UNRECOGNIZED`,
				value: COIN_FILTERS.UNRECOGNIZED,
			},
			{ label: 'ALL', value: COIN_FILTERS.ALL },
		],
		[balances],
	);

	const displayedBalances = useMemo(() => balances[filterValue], [balances, filterValue]);
	const hasCoinsBalance = balances.allBalances.length > 0;
	const coinBalanceHeader = hasCoinsBalance ? `${balances.allBalances.length} Coins` : 'Coins';

	if (isError) {
		return <div className="pt-2 font-sans font-semibold text-issue-dark">Failed to load Coins</div>;
	}
	return (
		<div className="h-full w-full md:pr-10">
			{isLoading ? (
				<div className="m-auto flex h-full w-full justify-center text-white">
					<LoadingIndicator />
				</div>
			) : (
				<div className="relative flex h-full flex-col gap-4 overflow-auto text-left">
					{/*max-sm:pb-3 border-gray-45 border-b sm:flex-row  */}
					<div className="defined-tabs-container relative flex  min-h-14 min-h-14 w-full flex-col  justify-between gap-y-3  max-sm:pt-5 sm:items-end">
						<div className="defined-tabs-div-item flex w-full justify-between sm:items-end">
							<Heading color="defined-title-text" variant="heading4/light">
								{coinBalanceHeader}
							</Heading>
							<ul className="flex sm:items-center">
								{filterOptions.map((filter) => (
									<li
										className={clsx(filterValue === filter.value ? 'active' : '')}
										key={filter.value}
										onClick={() => {
											setFilterValue(filter.value as COIN_FILTERS);
										}}
									>
										{filter.label}
									</li>
								))}
							</ul>
						</div>
						{/*<TabsList fullWidth>*/}
						{/*		onChange={(value) => {
								console.log('value', value);
								setFilterValue(value as COIN_FILTERS);
							}}*/}

						{/*{hasCoinsBalance && (*/}
						{/*	<>*/}
						{/*<RadioGroup*/}
						{/*	aria-label="transaction filter"*/}
						{/*	value={filterValue}*/}
						{/*	onValueChange={(value) => setFilterValue(value as COIN_FILTERS)}*/}
						{/*>*/}
						{/*	{filterOptions.map((filter) => (*/}
						{/*		<RadioGroupItem*/}
						{/*			key={filter.value}*/}
						{/*			value={filter.value}*/}
						{/*			label={filter.label}*/}
						{/*			disabled={!balances[filter.value].length}*/}
						{/*		/>*/}
						{/*	))}*/}
						{/*</RadioGroup>*/}
						{/*		<TabsTrigger key={coinBalanceHeader} value={coinBalanceHeader}>*/}
						{/*			{coinBalanceHeader}*/}
						{/*		</TabsTrigger>*/}
						{/*		{filterOptions.map((filter) => (*/}
						{/*			// <RadioGroupItem*/}
						{/*			// 	key={filter.value}*/}
						{/*			// 	value={filter.value}*/}
						{/*			// 	label={filter.label}*/}
						{/*			// 	disabled={!balances[filter.value].length}*/}
						{/*			// />*/}
						{/*			<TabsTrigger key={filter.value} value={filter.value}>*/}
						{/*				{filter.label}*/}
						{/*			</TabsTrigger>*/}
						{/*		))}*/}
						{/*	</>*/}
						{/*)}*/}
						{/*</TabsList>*/}
					</div>
					{filterValue === COIN_FILTERS.UNRECOGNIZED && (
						<div className="flex items-center gap-2 rounded-2xl border border-gray-45 p-2 text-steel-darker">
							<div>
								<Info16 width="16px" />
							</div>
							<Text color="steel-darker" variant="body/medium">
								These coins have not been recognized by Man Foundation.
							</Text>
						</div>
					)}

					{hasCoinsBalance && (
						<>
							<div className="flex max-h-coinsAndAssetsContainer flex-col overflow-auto md:max-h-full">
								<div className="mb-2.5 flex uppercase tracking-wider text-gray-80">
									<div className="w-[45%] pl-3">
										<Text variant="caption/medium" color="steel-dark">
											Type
										</Text>
									</div>
									<div className="w-[25%] px-2">
										<Text variant="caption/medium" color="steel-dark">
											Objects
										</Text>
									</div>
									<div className="w-[30%]">
										<Text variant="caption/medium" color="steel-dark">
											Balance
										</Text>
									</div>
								</div>
								<div>
									{displayedBalances
										.slice((currentSlice - 1) * limit, currentSlice * limit)
										.map((coin) => (
											<OwnedCoinView id={id} key={coin.coinType} coin={coin} />
										))}
								</div>
							</div>
							{displayedBalances.length > limit && (
								<div className="flex flex-col justify-between gap-2 md:flex-row">
									<Pagination
										onNext={() => setCurrentSlice(currentSlice + 1)}
										hasNext={currentSlice !== Math.ceil(displayedBalances.length / limit)}
										hasPrev={currentSlice !== 1}
										onPrev={() => setCurrentSlice(currentSlice - 1)}
										onFirst={() => setCurrentSlice(1)}
									/>
									<div className="flex items-center gap-3">
										<Text variant="body/medium" color="steel-dark">
											{`Showing `}
											{(currentSlice - 1) * limit + 1}-
											{currentSlice * limit > displayedBalances.length
												? displayedBalances.length
												: currentSlice * limit}
										</Text>
										<select
											className={clsx(
												'defined-form-select form-select flex rounded-md border border-gray-45 px-3 py-2 pr-8 text-bodySmall font-medium leading-[1.2] text-steel-dark shadow-button',
												localTheme,
											)}
											value={limit}
											onChange={(e) => {
												setLimit(Number(e.target.value));
												setCurrentSlice(1);
											}}
										>
											<option value={20}>20 Per Page</option>
											<option value={40}>40 Per Page</option>
											<option value={60}>60 Per Page</option>
										</select>
									</div>
								</div>
							)}
						</>
					)}

					{!hasCoinsBalance && (
						<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
							<Text variant="body/medium" color="steel-dark">
								No Coins owned
							</Text>
						</div>
					)}
				</div>
			)}
		</div>
	);
}
