


/**
 *  ######################################
 *  ### DO NOT EDIT THIS FILE DIRECTLY ###
 *  ######################################
 *
 * This file is generated from:
 * /crates/man-open-rpc/spec/openrpc.json
 */

import type { GetReferenceGasPriceParams } from '@mango/man.js/client';
import type { UseManClientQueryOptions } from '../useManClientQuery';
import { useManClientQuery } from '../useManClientQuery';

export function useReferenceGasPrice(
	params: GetReferenceGasPriceParams = {},
	options?: UseManClientQueryOptions<'getReferenceGasPrice'>,
) {
	return useManClientQuery(
		{
			method: 'getReferenceGasPrice',
			params,
		},
		options,
	);
}
