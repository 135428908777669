

import { useQuery } from '@tanstack/react-query';
import { useManClient } from '@mango/dapp-kit';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

const MAN_NS_FEATURE_FLAG = 'manns';

// This should align with whatever names we want to be able to resolve.
const MAN_NS_DOMAINS = ['.man'];
export function isManNSName(name: string) {
	return MAN_NS_DOMAINS.some((domain) => name.endsWith(domain));
}

export function useManNSEnabled() {
	return useFeatureIsOn(MAN_NS_FEATURE_FLAG);
}

export function useResolveManNSAddress(name?: string | null) {
	const client = useManClient();
	const enabled = useManNSEnabled();

	return useQuery({
		queryKey: ['resolve-manns-address', name],
		queryFn: async () => {
			return await client.resolveNameServiceAddress({
				name: name!,
			});
		},
		enabled: !!name && enabled,
		refetchOnWindowFocus: false,
		retry: false,
	});
}

export function useResolveManNSName(address?: string | null) {
	const client = useManClient();
	const enabled = useManNSEnabled();

	return useQuery({
		queryKey: ['resolve-manns-name', address],
		queryFn: async () => {
			// NOTE: We only fetch 1 here because it's the default name.
			const { data } = await client.resolveNameServiceNames({
				address: address!,
				limit: 1,
			});

			return data[0] || null;
		},
		enabled: !!address && enabled,
		refetchOnWindowFocus: false,
		retry: false,
	});
}
