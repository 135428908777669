

import { darkTheme, styled } from './stitches';
import { Title } from './utils/Dialog';
import { ManIcon } from './utils/icons';
import { Truncate, Panel } from './utils/ui';
import { useWalletKit } from './WalletKitContext';

const Container = styled(Panel, {
	height: '100%',
	[`.${darkTheme} &`]: {
		background: '$definedBackground',
	},

	'@md': {
		background: '$definedBackground',
	},
});

const ListContainer = styled('div', {
	marginTop: '$6',
	display: 'flex',
	flexDirection: 'column',
	gap: '$1',
});

const WalletItem = styled('button', {
	background: 'none',
	display: 'flex',
	padding: '$2',
	gap: '$2',
	alignItems: 'center',
	cursor: 'pointer',
	color: '$textDark',
	border: 'none',
	fontWeight: '$button',
	fontSize: '$md',
	borderRadius: '$wallet',

	variants: {
		selected: {
			true: {
				background: '$definedBackground2',
				color: '#fff',
				boxShadow: '$wallet',
			},
		},
	},
});

const WalletIcon = styled('img', {
	flexShrink: 0,
	background: 'white',
	width: 28,
	height: 28,
	borderRadius: 6,
	objectFit: 'cover',
});

interface Props {
	selected: string | null;
	onChange(selected: string): void;
}

export const SELECTED_GETTING_STARTED = '@@internal/getting-started';

export function WalletList({ selected, onChange }: Props) {
	const { wallets } = useWalletKit();
	console.log('last', wallets.length === 0);
	return (
		<Container>
			<Title>Connect a Wallet</Title>

			<ListContainer>
				{wallets.length === 0 ? (
					<WalletItem
						onClick={() => onChange(SELECTED_GETTING_STARTED)}
						selected={{ '@initial': false, '@md': true }}
					>
						<ManIcon />
						<Truncate>Mango Wallet</Truncate>
					</WalletItem>
				) : (
					wallets.map((wallet) => {
						return (
							<WalletItem
								key={wallet.name}
								selected={wallet.name === selected}
								onClick={() => {
									onChange(wallet.name);
								}}
							>
								{wallet.name === 'Man Wallet' ? <ManIcon /> : <WalletIcon src={wallet.icon} />}
								<Truncate>{wallet.name === 'Man Wallet' ? 'Mango Wallet' : wallet.name}</Truncate>
							</WalletItem>
						);
					})
				)}
			</ListContainer>
		</Container>
	);
}
