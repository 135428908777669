
import {
	ManObjectChangeTransferred,
	ManObjectChangeCreated,
	ManObjectChangeMutated,
	ManObjectChangePublished,
	ManObjectChange,
	DisplayFieldsResponse,
	ManObjectChangeDeleted,
	ManObjectChangeWrapped,
} from '@mango/man.js/client';
import { groupByOwner } from './groupByOwner';
import { ManObjectChangeTypes } from './types';

export type WithDisplayFields<T> = T & { display?: DisplayFieldsResponse };
export type ManObjectChangeWithDisplay = WithDisplayFields<ManObjectChange>;

export type ObjectChanges = {
	changesWithDisplay: ManObjectChangeWithDisplay[];
	changes: ManObjectChange[];
	ownerType: string;
};
export type ObjectChangesByOwner = Record<string, ObjectChanges>;

export type ObjectChangeSummary = {
	[K in ManObjectChangeTypes]: ObjectChangesByOwner;
};

export const getObjectChangeSummary = (objectChanges: ManObjectChangeWithDisplay[]) => {
	if (!objectChanges) return null;

	const mutated = objectChanges.filter(
		(change) => change.type === 'mutated',
	) as ManObjectChangeMutated[];

	const created = objectChanges.filter(
		(change) => change.type === 'created',
	) as ManObjectChangeCreated[];

	const transferred = objectChanges.filter(
		(change) => change.type === 'transferred',
	) as ManObjectChangeTransferred[];

	const published = objectChanges.filter(
		(change) => change.type === 'published',
	) as ManObjectChangePublished[];

	const wrapped = objectChanges.filter(
		(change) => change.type === 'wrapped',
	) as ManObjectChangeWrapped[];

	const deleted = objectChanges.filter(
		(change) => change.type === 'deleted',
	) as ManObjectChangeDeleted[];

	return {
		transferred: groupByOwner(transferred),
		created: groupByOwner(created),
		mutated: groupByOwner(mutated),
		published: groupByOwner(published),
		wrapped: groupByOwner(wrapped),
		deleted: groupByOwner(deleted),
	};
};
