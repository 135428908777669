
import { SVGProps } from 'react';
const SvgNft16 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 16 16"
		{...props}
	>
		<g clipPath="url(#NFT_16_svg__a)">
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M2.75.25A.75.75 0 0 1 3.5 1v.96h1a.75.75 0 1 1 0 1.5h-1v.961a.75.75 0 1 1-1.5 0v-.96H1a.75.75 0 0 1 0-1.5h1V1a.75.75 0 0 1 .75-.75ZM8.7.934a.75.75 0 0 1 .698.476l1.214 3.085c.211.537.274.681.358.797.086.118.193.223.316.308.121.084.273.147.822.354l3.156 1.186a.75.75 0 0 1 0 1.404l-3.156 1.187c-.55.206-.7.269-.822.353-.123.085-.23.19-.316.309-.084.115-.147.259-.358.797l-1.214 3.085a.75.75 0 0 1-1.396 0L6.788 11.19c-.211-.538-.274-.682-.358-.797a1.327 1.327 0 0 0-.316-.309c-.121-.084-.273-.147-.822-.353L2.136 8.544a.75.75 0 0 1 0-1.404l3.156-1.186c.55-.207.7-.27.822-.354.123-.085.23-.19.316-.308.084-.116.147-.26.358-.797L8.002 1.41A.75.75 0 0 1 8.7.934Zm0 2.798-.516 1.312-.029.073c-.169.43-.301.767-.512 1.057a2.827 2.827 0 0 1-.673.658c-.294.204-.635.332-1.076.498l-.074.028-1.289.484 1.29.485.073.027c.441.166.782.294 1.076.498.26.18.487.403.673.658.211.29.343.627.512 1.057l.029.073.516 1.312.516-1.312.029-.073c.169-.43.3-.767.512-1.057.186-.255.413-.477.673-.658.294-.204.635-.332 1.076-.498l.074-.027 1.289-.485-1.29-.484c-.024-.01-.049-.02-.073-.028-.441-.166-.782-.294-1.076-.498a2.828 2.828 0 0 1-.673-.658c-.211-.29-.343-.626-.512-1.057l-.029-.073L8.7 3.732Zm-7.528 7.595a.75.75 0 0 1 1.06 0l.69.69.69-.69a.75.75 0 1 1 1.06 1.061l-.689.69.69.69a.75.75 0 0 1-1.061 1.06l-.69-.69-.69.69a.75.75 0 1 1-1.06-1.06l.69-.69-.69-.69a.75.75 0 0 1 0-1.06Z"
				clipRule="evenodd"
			/>
		</g>
		<defs>
			<clipPath id="NFT_16_svg__a">
				<path fill="#fff" d="M0 0h16v16H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgNft16;
