

import { Wallet } from '@wallet-standard/core';
import { WalletWithManFeatures } from './features';

// These features are absolutely required for wallets to function in the Man ecosystem.
// Eventually, as wallets have more consistent support of features, we may want to extend this list.
const REQUIRED_FEATURES: (keyof WalletWithManFeatures['features'])[] = [
	'standard:connect',
	'standard:events',
];

export function isWalletWithManFeatures(
	wallet: Wallet,
	/** Extra features that are required to be present, in addition to the expected feature set. */
	features: string[] = [],
): wallet is WalletWithManFeatures {
	return [...REQUIRED_FEATURES, ...features].every((feature) => feature in wallet.features);
}
