

import { type ManTransactionBlockResponse } from '@mango/man.js/client';
import {
	parseSerializedSignature,
	type SignatureScheme,
	type PublicKey,
} from '@mango/man.js/cryptography';
import { parsePartialSignatures } from '@mango/man.js/multisig';
import { toB64, normalizeManAddress } from '@mango/man.js/utils';
import { publicKeyFromRawBytes } from '@mango/man.js/verify';
import { Text } from '@mango/ui';

import { DescriptionItem, DescriptionList } from '~/ui/DescriptionList';
import { AddressLink } from '~/ui/InternalLink';
import { TabHeader } from '~/ui/Tabs';

interface SignaturePubkeyPair {
	signatureScheme: SignatureScheme;
	publicKey: PublicKey;
	signature: Uint8Array;
}

function SignaturePanel({ title, signature }: { title: string; signature: SignaturePubkeyPair }) {
	return (
		<TabHeader title={title}>
			<DescriptionList>
				<DescriptionItem title="Scheme" align="start" color="signatures" labelWidth="sm">
					<Text variant="pBody/medium" color="steel-darker">
						{signature.signatureScheme}
					</Text>
				</DescriptionItem>
				<DescriptionItem title="Address" align="start" color="signatures" labelWidth="sm">
					<AddressLink
						className="defined-signatures-link-color break-all font-mono text-body font-medium text-hero-dark hover:text-hero-darkest"
						noTruncate
						address={signature.publicKey.toManAddress()}
					/>
				</DescriptionItem>
				<DescriptionItem title="Man Public Key" align="start" color="signatures" labelWidth="sm">
					<Text variant="pBody/medium" color="steel-darker">
						{signature.publicKey.toManPublicKey()}
					</Text>
				</DescriptionItem>
				<DescriptionItem title="Signature" align="start" color="signatures" labelWidth="sm">
					<Text variant="pBody/medium" color="steel-darker">
						{toB64(signature.signature)}
					</Text>
				</DescriptionItem>
			</DescriptionList>
		</TabHeader>
	);
}

function getSignatureFromAddress(signatures: SignaturePubkeyPair[], manAddress: string) {
	return signatures.find(
		(signature) => signature.publicKey.toManAddress() === normalizeManAddress(manAddress),
	);
}

function getSignaturesExcludingAddress(
	signatures: SignaturePubkeyPair[],
	manAddress: string,
): SignaturePubkeyPair[] {
	return signatures.filter(
		(signature) => signature.publicKey.toManAddress() !== normalizeManAddress(manAddress),
	);
}
interface Props {
	transaction: ManTransactionBlockResponse;
}

export function Signatures({ transaction }: Props) {
	const sender = transaction.transaction?.data.sender;
	const gasData = transaction.transaction?.data.gasData;
	const transactionSignatures = transaction.transaction?.txSignatures;

	if (!transactionSignatures) return null;

	const isSponsoredTransaction = gasData?.owner !== sender;

	const deserializedTransactionSignatures = transactionSignatures
		.map((signature) => {
			const parsed = parseSerializedSignature(signature);

			if (parsed.signatureScheme === 'MultiSig') {
				return parsePartialSignatures(parsed.multisig);
			}

			return {
				...parsed,
				publicKey: publicKeyFromRawBytes(parsed.signatureScheme, parsed.publicKey),
			};
		})
		.flat();

	const userSignatures = isSponsoredTransaction
		? getSignaturesExcludingAddress(deserializedTransactionSignatures, gasData!.owner)
		: deserializedTransactionSignatures;

	const sponsorSignature = isSponsoredTransaction
		? getSignatureFromAddress(deserializedTransactionSignatures, gasData!.owner)
		: null;

	return (
		<div className="flex flex-col gap-8">
			{userSignatures.length > 0 && (
				<div className="flex flex-col gap-8">
					{userSignatures.map((signature, index) => (
						<div key={index}>
							<SignaturePanel title="User Signature" signature={signature} />
						</div>
					))}
				</div>
			)}
			{sponsorSignature && (
				<SignaturePanel title="Sponsor Signature" signature={sponsorSignature} />
			)}
		</div>
	);
}
