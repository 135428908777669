

import { useQuery } from '@tanstack/react-query';
import { useAppsBackend } from './useAppsBackend';

// TODO: We should consider using tRPC or something for apps-backend
type CoinData = {
	marketCap: string;
	fullyDilutedMarketCap: string;
	currentPrice: number;
	priceChangePercentageOver24H: number;
	circulatingSupply: number;
	totalSupply: number;
};

export const COIN_GECKO_MAN_URL = 'https://www.coingecko.com/en/coins/ethereum';

export function useManCoinData() {
	const { request } = useAppsBackend();
	return useQuery({
		queryKey: ['man-coin-data'],
		queryFn: () => request<CoinData>('coins/man', {}),
		cacheTime: 24 * 60 * 60 * 1000,
		staleTime: Infinity,
	});
}
