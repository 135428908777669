

import Highlight, { defaultProps } from 'prism-react-renderer';
import 'prism-themes/themes/prism-one-light.css';

import { useTheme } from '~/context';

import type { Language } from 'prism-react-renderer';

import clsx from 'clsx';

interface Props {
	code: string;
	language: Language;
}

export function SyntaxHighlighter({ code, language }: Props) {
	const [localTheme] = useTheme();
	return (
		<div className="overflow-auto whitespace-pre font-mono text-sm">
			<Highlight {...defaultProps} code={code} language={language} theme={undefined}>
				{({ style, tokens, getLineProps, getTokenProps }) => (
					<pre className="overflow-auto bg-transparent !p-0 font-medium" style={style}>
						{tokens.map((line, i) => (
							<div {...getLineProps({ line, key: i })} key={i} className="table-row">
								<div
									className={clsx(
										'table-cell select-none pr-4 text-left opacity-50',
										localTheme !== 'light' ? '' : 'is-dark',
									)}
								>
									{i + 1}
								</div>

								{line.map((token, key) => {
									const propsParams = getTokenProps({
										token,
										key,
									});
									propsParams.children = propsParams.children
										.replace(/Sui/g, 'Man')
										.replace(/sui/g, 'man');
									return <span {...propsParams} key={key} />;
								})}
							</div>
						))}
					</pre>
				)}
			</Highlight>
		</div>
	);
}
