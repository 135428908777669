

export function getFullnodeUrl(network: 'mainnet' | 'testnet' | 'devnet' | 'localnet') {
	switch (network) {
		case 'mainnet':
			return 'https://fullnode.mainnet.man.io:443';
		case 'testnet':
			return 'https://fullnode.testnet.man.io:443';
		case 'devnet':
			return 'https://fullnode.devnet.man.io:443';
		case 'localnet':
			return 'http://127.0.0.1:9000';
		default:
			throw new Error(`Unknown network: ${network}`);
	}
}
