
import {SVGProps} from 'react';

const SvgCoinLogo = (props: SVGProps<SVGSVGElement>) => (
    // <svg
    //     xmlns="http://www.w3.org/2000/svg"
    //     width="1em"
    //     height="1em"
    //     fill="none"
    //     viewBox="0 0 16 16"
    //     {...props}
    // >
    //     <path
    //         fill="currentColor"
    //         fillRule="evenodd"
    //         d="M7.333 3.917a3.417 3.417 0 1 0 0 6.833 3.417 3.417 0 0 0 0-6.833ZM2.417 7.333A4.917 4.917 0 1 1 11.3 10.24l2.064 2.064a.75.75 0 0 1-1.061 1.06L10.239 11.3a4.917 4.917 0 0 1-7.822-3.966Z"
    //         clipRule="evenodd"
    //     />
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" version="1.1" width="64"  {...props} height="64"
         viewBox="0 0 64 64">
        <defs>
            <clipPath id="master_svg0_47_19756">
                <rect x="0" y="0" width="64" height="64" rx="0"/>
            </clipPath>
        </defs>
        <g clipPath="url(#master_svg0_47_19756)">
            <g>
                <rect x="0" y="0" width="64" height="64" rx="32" fill="#46ADB3"/>
            </g>
            <g>
                <path
                    d="M20,48L26.2195,43.5L26.21951,31.7812L31.25,35.0625L31.25,26.0625L20,18L20,48ZM44,48L37.7805,43.5L37.7805,31.7812L32.75,35.0625L32.75,26.0625L44,18L44,48Z"
                    fillRule="evenodd" fill="#FFFFFF"/>
            </g>
        </g>
    </svg>
);
export default SvgCoinLogo;
