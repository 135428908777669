

import type { Infer } from 'superstruct';
import {
	any,
	array,
	assign,
	boolean,
	literal,
	number,
	object,
	optional,
	record,
	string,
	union,
	is,
	nullable,
	tuple,
	unknown,
} from 'superstruct';
import { ObjectOwner } from './common.js';
import type { OwnedObjectRef } from './transactions.js';

export const ObjectType = union([string(), literal('package')]);
export type ObjectType = Infer<typeof ObjectType>;

export const ManObjectRef = object({
	/** Base64 string representing the object digest */
	digest: string(),
	/** Hex code as string representing the object id */
	objectId: string(),
	/** Object version */
	version: union([number(), string()]),
});
export type ManObjectRef = Infer<typeof ManObjectRef>;

export const ManGasData = object({
	payment: array(ManObjectRef),
	/** Gas Object's owner */
	owner: string(),
	price: string(),
	budget: string(),
});
export type ManGasData = Infer<typeof ManGasData>;

export const ManObjectInfo = assign(
	ManObjectRef,
	object({
		type: string(),
		owner: ObjectOwner,
		previousTransaction: string(),
	}),
);
export type ManObjectInfo = Infer<typeof ManObjectInfo>;

export const ObjectContentFields = record(string(), any());
export type ObjectContentFields = Infer<typeof ObjectContentFields>;

export const MovePackageContent = record(string(), unknown());
export type MovePackageContent = Infer<typeof MovePackageContent>;

export const ManMoveObject = object({
	/** Move type (e.g., "0x2::coin::Coin<0x2::man::MAN>") */
	type: string(),
	/** Fields and values stored inside the Move object */
	fields: ObjectContentFields,
	hasPublicTransfer: boolean(),
});
export type ManMoveObject = Infer<typeof ManMoveObject>;

export const ManMovePackage = object({
	/** A mapping from module name to disassembled Move bytecode */
	disassembled: MovePackageContent,
});
export type ManMovePackage = Infer<typeof ManMovePackage>;

export const ManParsedData = union([
	assign(ManMoveObject, object({ dataType: literal('moveObject') })),
	assign(ManMovePackage, object({ dataType: literal('package') })),
]);
export type ManParsedData = Infer<typeof ManParsedData>;

export const ManRawMoveObject = object({
	/** Move type (e.g., "0x2::coin::Coin<0x2::man::MAN>") */
	type: string(),
	hasPublicTransfer: boolean(),
	version: string(),
	bcsBytes: string(),
});
export type ManRawMoveObject = Infer<typeof ManRawMoveObject>;

export const ManRawMovePackage = object({
	id: string(),
	/** A mapping from module name to Move bytecode enocded in base64*/
	moduleMap: record(string(), string()),
});
export type ManRawMovePackage = Infer<typeof ManRawMovePackage>;

export const ManRawData = union([
	assign(ManRawMoveObject, object({ dataType: literal('moveObject') })),
	assign(ManRawMovePackage, object({ dataType: literal('package') })),
]);
export type ManRawData = Infer<typeof ManRawData>;

export const MAN_DECIMALS = 9;

export const MIST_PER_MAN = BigInt(1000000000);

/** @deprecated Use `string` instead. */
export const ObjectDigest = string();
/** @deprecated Use `string` instead. */
export type ObjectDigest = Infer<typeof ObjectDigest>;

export const ManObjectResponseError = object({
	code: string(),
	error: optional(string()),
	object_id: optional(string()),
	parent_object_id: optional(string()),
	version: optional(string()),
	digest: optional(string()),
});
export type ManObjectResponseError = Infer<typeof ManObjectResponseError>;
export const DisplayFieldsResponse = object({
	data: nullable(optional(record(string(), string()))),
	error: nullable(optional(ManObjectResponseError)),
});
export type DisplayFieldsResponse = Infer<typeof DisplayFieldsResponse>;
export const DisplayFieldsBackwardCompatibleResponse = union([
	DisplayFieldsResponse,
	optional(record(string(), string())),
]);
export type DisplayFieldsBackwardCompatibleResponse = Infer<
	typeof DisplayFieldsBackwardCompatibleResponse
>;

export const ManObjectData = object({
	objectId: string(),
	version: string(),
	digest: string(),
	/**
	 * Type of the object, default to be undefined unless ManObjectDataOptions.showType is set to true
	 */
	type: nullable(optional(string())),
	/**
	 * Move object content or package content, default to be undefined unless ManObjectDataOptions.showContent is set to true
	 */
	content: nullable(optional(ManParsedData)),
	/**
	 * Move object content or package content in BCS bytes, default to be undefined unless ManObjectDataOptions.showBcs is set to true
	 */
	bcs: nullable(optional(ManRawData)),
	/**
	 * The owner of this object. Default to be undefined unless ManObjectDataOptions.showOwner is set to true
	 */
	owner: nullable(optional(ObjectOwner)),
	/**
	 * The digest of the transaction that created or last mutated this object.
	 * Default to be undefined unless ManObjectDataOptions.showPreviousTransaction is set to true
	 */
	previousTransaction: nullable(optional(string())),
	/**
	 * The amount of MAN we would rebate if this object gets deleted.
	 * This number is re-calculated each time the object is mutated based on
	 * the present storage gas price.
	 * Default to be undefined unless ManObjectDataOptions.showStorageRebate is set to true
	 */
	storageRebate: nullable(optional(string())),
	/**
	 * Display metadata for this object, default to be undefined unless ManObjectDataOptions.showDisplay is set to true
	 * This can also be None if the struct type does not have Display defined
	 */
	display: nullable(optional(DisplayFieldsBackwardCompatibleResponse)),
});
export type ManObjectData = Infer<typeof ManObjectData>;

/**
 * Config for fetching object data
 */
export const ManObjectDataOptions = object({
	/* Whether to fetch the object type, default to be true */
	showType: nullable(optional(boolean())),
	/* Whether to fetch the object content, default to be false */
	showContent: nullable(optional(boolean())),
	/* Whether to fetch the object content in BCS bytes, default to be false */
	showBcs: nullable(optional(boolean())),
	/* Whether to fetch the object owner, default to be false */
	showOwner: nullable(optional(boolean())),
	/* Whether to fetch the previous transaction digest, default to be false */
	showPreviousTransaction: nullable(optional(boolean())),
	/* Whether to fetch the storage rebate, default to be false */
	showStorageRebate: nullable(optional(boolean())),
	/* Whether to fetch the display metadata, default to be false */
	showDisplay: nullable(optional(boolean())),
});
export type ManObjectDataOptions = Infer<typeof ManObjectDataOptions>;

export const ObjectStatus = union([literal('Exists'), literal('notExists'), literal('Deleted')]);
export type ObjectStatus = Infer<typeof ObjectStatus>;

export const GetOwnedObjectsResponse = array(ManObjectInfo);
export type GetOwnedObjectsResponse = Infer<typeof GetOwnedObjectsResponse>;

export const ManObjectResponse = object({
	data: nullable(optional(ManObjectData)),
	error: nullable(optional(ManObjectResponseError)),
});
export type ManObjectResponse = Infer<typeof ManObjectResponse>;

export type Order = 'ascending' | 'descending';

/* -------------------------------------------------------------------------- */
/*                              Helper functions                              */
/* -------------------------------------------------------------------------- */

/* -------------------------- ManObjectResponse ------------------------- */

export function getManObjectData(resp: ManObjectResponse): ManObjectData | null | undefined {
	return resp.data;
}

export function getObjectDeletedResponse(resp: ManObjectResponse): ManObjectRef | undefined {
	if (
		resp.error &&
		'object_id' in resp.error &&
		'version' in resp.error &&
		'digest' in resp.error
	) {
		const error = resp.error as ManObjectResponseError;
		return {
			objectId: error.object_id,
			version: error.version,
			digest: error.digest,
		} as ManObjectRef;
	}

	return undefined;
}

export function getObjectNotExistsResponse(resp: ManObjectResponse): string | undefined {
	if (
		resp.error &&
		'object_id' in resp.error &&
		!('version' in resp.error) &&
		!('digest' in resp.error)
	) {
		return (resp.error as ManObjectResponseError).object_id as string;
	}

	return undefined;
}

export function getObjectReference(
	resp: ManObjectResponse | OwnedObjectRef,
): ManObjectRef | undefined {
	if ('reference' in resp) {
		return resp.reference;
	}
	const exists = getManObjectData(resp);
	if (exists) {
		return {
			objectId: exists.objectId,
			version: exists.version,
			digest: exists.digest,
		};
	}
	return getObjectDeletedResponse(resp);
}

/* ------------------------------ ManObjectRef ------------------------------ */

export function getObjectId(data: ManObjectResponse | ManObjectRef | OwnedObjectRef): string {
	if ('objectId' in data) {
		return data.objectId;
	}
	return (
		getObjectReference(data)?.objectId ?? getObjectNotExistsResponse(data as ManObjectResponse)!
	);
}

export function getObjectVersion(
	data: ManObjectResponse | ManObjectRef | ManObjectData,
): string | number | undefined {
	if ('version' in data) {
		return data.version;
	}
	return getObjectReference(data)?.version;
}

/* -------------------------------- ManObject ------------------------------- */

export function isManObjectResponse(
	resp: ManObjectResponse | ManObjectData,
): resp is ManObjectResponse {
	return (resp as ManObjectResponse).data !== undefined;
}

/**
 * Deriving the object type from the object response
 * @returns 'package' if the object is a package, move object type(e.g., 0x2::coin::Coin<0x2::man::MAN>)
 * if the object is a move object
 */
export function getObjectType(
	resp: ManObjectResponse | ManObjectData,
): ObjectType | null | undefined {
	const data = isManObjectResponse(resp) ? resp.data : resp;

	if (!data?.type && 'data' in resp) {
		if (data?.content?.dataType === 'package') {
			return 'package';
		}
		return getMoveObjectType(resp);
	}
	return data?.type;
}

export function getObjectPreviousTransactionDigest(
	resp: ManObjectResponse,
): string | null | undefined {
	return getManObjectData(resp)?.previousTransaction;
}

export function getObjectOwner(
	resp: ManObjectResponse | ObjectOwner,
): ObjectOwner | null | undefined {
	if (is(resp, ObjectOwner)) {
		return resp;
	}
	return getManObjectData(resp)?.owner;
}

export function getObjectDisplay(resp: ManObjectResponse): DisplayFieldsResponse {
	const display = getManObjectData(resp)?.display;
	if (!display) {
		return { data: null, error: null };
	}
	if (is(display, DisplayFieldsResponse)) {
		return display;
	}
	return {
		data: display,
		error: null,
	};
}

export function getSharedObjectInitialVersion(
	resp: ManObjectResponse | ObjectOwner,
): string | null | undefined {
	const owner = getObjectOwner(resp);
	if (owner && typeof owner === 'object' && 'Shared' in owner) {
		return owner.Shared.initial_shared_version;
	} else {
		return undefined;
	}
}

export function isSharedObject(resp: ManObjectResponse | ObjectOwner): boolean {
	const owner = getObjectOwner(resp);
	return !!owner && typeof owner === 'object' && 'Shared' in owner;
}

export function isImmutableObject(resp: ManObjectResponse | ObjectOwner): boolean {
	const owner = getObjectOwner(resp);
	return owner === 'Immutable';
}

export function getMoveObjectType(resp: ManObjectResponse): string | undefined {
	return getMoveObject(resp)?.type;
}

export function getObjectFields(
	resp: ManObjectResponse | ManMoveObject | ManObjectData,
): ObjectContentFields | undefined {
	if ('fields' in resp) {
		return resp.fields;
	}
	return getMoveObject(resp)?.fields;
}

export interface ManObjectDataWithContent extends ManObjectData {
	content: ManParsedData;
}

function isManObjectDataWithContent(data: ManObjectData): data is ManObjectDataWithContent {
	return data.content !== undefined;
}

export function getMoveObject(data: ManObjectResponse | ManObjectData): ManMoveObject | undefined {
	const manObject = 'data' in data ? getManObjectData(data) : (data as ManObjectData);

	if (
		!manObject ||
		!isManObjectDataWithContent(manObject) ||
		manObject.content.dataType !== 'moveObject'
	) {
		return undefined;
	}

	return manObject.content as ManMoveObject;
}

export function hasPublicTransfer(data: ManObjectResponse | ManObjectData): boolean {
	return getMoveObject(data)?.hasPublicTransfer ?? false;
}

export function getMovePackageContent(
	data: ManObjectResponse | ManMovePackage,
): MovePackageContent | undefined {
	if ('disassembled' in data) {
		return data.disassembled;
	}
	const manObject = getManObjectData(data);
	if (manObject?.content?.dataType !== 'package') {
		return undefined;
	}
	return (manObject.content as ManMovePackage).disassembled;
}

export const CheckpointedObjectId = object({
	objectId: string(),
	atCheckpoint: optional(number()),
});
export type CheckpointedObjectId = Infer<typeof CheckpointedObjectId>;

export const PaginatedObjectsResponse = object({
	data: array(ManObjectResponse),
	nextCursor: optional(nullable(string())),
	hasNextPage: boolean(),
});
export type PaginatedObjectsResponse = Infer<typeof PaginatedObjectsResponse>;

// mirrors man_json_rpc_types:: ManObjectDataFilter
export type ManObjectDataFilter =
	| { MatchAll: ManObjectDataFilter[] }
	| { MatchAny: ManObjectDataFilter[] }
	| { MatchNone: ManObjectDataFilter[] }
	| { Package: string }
	| { MoveModule: { package: string; module: string } }
	| { StructType: string }
	| { AddressOwner: string }
	| { ObjectOwner: string }
	| { ObjectId: string }
	| { ObjectIds: string[] }
	| { Version: string };

export type ManObjectResponseQuery = {
	filter?: ManObjectDataFilter;
	options?: ManObjectDataOptions;
};

export const ObjectRead = union([
	object({
		details: ManObjectData,
		status: literal('VersionFound'),
	}),
	object({
		details: string(),
		status: literal('ObjectNotExists'),
	}),
	object({
		details: ManObjectRef,
		status: literal('ObjectDeleted'),
	}),
	object({
		details: tuple([string(), number()]),
		status: literal('VersionNotFound'),
	}),
	object({
		details: object({
			asked_version: number(),
			latest_version: number(),
			object_id: string(),
		}),
		status: literal('VersionTooHigh'),
	}),
]);
export type ObjectRead = Infer<typeof ObjectRead>;
