

import type { Infer } from 'superstruct';
import {
	array,
	object,
	string,
	union,
	boolean,
	define,
	number,
	literal,
	record,
	is,
	tuple,
} from 'superstruct';

export type ManMoveFunctionArgTypesResponse = Infer<typeof ManMoveFunctionArgType>[];

export const ManMoveFunctionArgType = union([string(), object({ Object: string() })]);

export const ManMoveFunctionArgTypes = array(ManMoveFunctionArgType);
export type ManMoveFunctionArgTypes = Infer<typeof ManMoveFunctionArgTypes>;

export const ManMoveModuleId = object({
	address: string(),
	name: string(),
});
export type ManMoveModuleId = Infer<typeof ManMoveModuleId>;

export const ManMoveVisibility = union([literal('Private'), literal('Public'), literal('Friend')]);
export type ManMoveVisibility = Infer<typeof ManMoveVisibility>;

export const ManMoveAbilitySet = object({
	abilities: array(string()),
});
export type ManMoveAbilitySet = Infer<typeof ManMoveAbilitySet>;

export const ManMoveStructTypeParameter = object({
	constraints: ManMoveAbilitySet,
	isPhantom: boolean(),
});
export type ManMoveStructTypeParameter = Infer<typeof ManMoveStructTypeParameter>;

export const ManMoveNormalizedTypeParameterType = object({
	TypeParameter: number(),
});
export type ManMoveNormalizedTypeParameterType = Infer<typeof ManMoveNormalizedTypeParameterType>;

export type ManMoveNormalizedType =
	| string
	| ManMoveNormalizedTypeParameterType
	| { Reference: ManMoveNormalizedType }
	| { MutableReference: ManMoveNormalizedType }
	| { Vector: ManMoveNormalizedType }
	| ManMoveNormalizedStructType;

export const MoveCallMetric = tuple([
	object({
		module: string(),
		package: string(),
		function: string(),
	}),
	string(),
]);

export type MoveCallMetric = Infer<typeof MoveCallMetric>;

export const MoveCallMetrics = object({
	rank3Days: array(MoveCallMetric),
	rank7Days: array(MoveCallMetric),
	rank30Days: array(MoveCallMetric),
});

export type MoveCallMetrics = Infer<typeof MoveCallMetrics>;

function isManMoveNormalizedType(value: unknown): value is ManMoveNormalizedType {
	if (!value) return false;
	if (typeof value === 'string') return true;
	if (is(value, ManMoveNormalizedTypeParameterType)) return true;
	if (isManMoveNormalizedStructType(value)) return true;
	if (typeof value !== 'object') return false;

	const valueProperties = value as Record<string, unknown>;
	if (is(valueProperties.Reference, ManMoveNormalizedType)) return true;
	if (is(valueProperties.MutableReference, ManMoveNormalizedType)) return true;
	if (is(valueProperties.Vector, ManMoveNormalizedType)) return true;
	return false;
}

export const ManMoveNormalizedType = define<ManMoveNormalizedType>(
	'ManMoveNormalizedType',
	isManMoveNormalizedType,
);

export type ManMoveNormalizedStructType = {
	Struct: {
		address: string;
		module: string;
		name: string;
		typeArguments: ManMoveNormalizedType[];
	};
};

function isManMoveNormalizedStructType(value: unknown): value is ManMoveNormalizedStructType {
	if (!value || typeof value !== 'object') return false;

	const valueProperties = value as Record<string, unknown>;
	if (!valueProperties.Struct || typeof valueProperties.Struct !== 'object') return false;

	const structProperties = valueProperties.Struct as Record<string, unknown>;
	if (
		typeof structProperties.address !== 'string' ||
		typeof structProperties.module !== 'string' ||
		typeof structProperties.name !== 'string' ||
		!Array.isArray(structProperties.typeArguments) ||
		!structProperties.typeArguments.every((value) => isManMoveNormalizedType(value))
	) {
		return false;
	}

	return true;
}

// NOTE: This type is recursive, so we need to manually implement it:
export const ManMoveNormalizedStructType = define<ManMoveNormalizedStructType>(
	'ManMoveNormalizedStructType',
	isManMoveNormalizedStructType,
);

export const ManMoveNormalizedFunction = object({
	visibility: ManMoveVisibility,
	isEntry: boolean(),
	typeParameters: array(ManMoveAbilitySet),
	parameters: array(ManMoveNormalizedType),
	return: array(ManMoveNormalizedType),
});
export type ManMoveNormalizedFunction = Infer<typeof ManMoveNormalizedFunction>;

export const ManMoveNormalizedField = object({
	name: string(),
	type: ManMoveNormalizedType,
});
export type ManMoveNormalizedField = Infer<typeof ManMoveNormalizedField>;

export const ManMoveNormalizedStruct = object({
	abilities: ManMoveAbilitySet,
	typeParameters: array(ManMoveStructTypeParameter),
	fields: array(ManMoveNormalizedField),
});
export type ManMoveNormalizedStruct = Infer<typeof ManMoveNormalizedStruct>;

export const ManMoveNormalizedModule = object({
	fileFormatVersion: number(),
	address: string(),
	name: string(),
	friends: array(ManMoveModuleId),
	structs: record(string(), ManMoveNormalizedStruct),
	exposedFunctions: record(string(), ManMoveNormalizedFunction),
});
export type ManMoveNormalizedModule = Infer<typeof ManMoveNormalizedModule>;

export const ManMoveNormalizedModules = record(string(), ManMoveNormalizedModule);
export type ManMoveNormalizedModules = Infer<typeof ManMoveNormalizedModules>;

export function extractMutableReference(
	normalizedType: ManMoveNormalizedType,
): ManMoveNormalizedType | undefined {
	return typeof normalizedType === 'object' && 'MutableReference' in normalizedType
		? normalizedType.MutableReference
		: undefined;
}

export function extractReference(
	normalizedType: ManMoveNormalizedType,
): ManMoveNormalizedType | undefined {
	return typeof normalizedType === 'object' && 'Reference' in normalizedType
		? normalizedType.Reference
		: undefined;
}

export function extractStructTag(
	normalizedType: ManMoveNormalizedType,
): ManMoveNormalizedStructType | undefined {
	if (typeof normalizedType === 'object' && 'Struct' in normalizedType) {
		return normalizedType;
	}

	const ref = extractReference(normalizedType);
	const mutRef = extractMutableReference(normalizedType);

	if (typeof ref === 'object' && 'Struct' in ref) {
		return ref;
	}

	if (typeof mutRef === 'object' && 'Struct' in mutRef) {
		return mutRef;
	}
	return undefined;
}
