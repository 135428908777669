
import clsx from 'clsx';
import React from 'react';

import { ReactComponent as Dark } from '../../assets/dark.svg';
import { ReactComponent as Light } from '../../assets/light.svg';
import { useTheme } from '~/context';
import { Theme } from '~/utils/api/DefaultRpcClient';
export default function ThemeSelect() {
	const [localTheme, setTheme] = useTheme();
	return (
		<div className={clsx('theme-select-wrap')}>
			<div
				className={clsx('theme-select-btn')}
				onClick={() => {
					setTheme(localTheme === Theme.Light ? Theme.Dark : Theme.Light);
				}}
			>
				{localTheme === 'light' ? <Light /> : <Dark />}
			</div>
		</div>
	);
}
