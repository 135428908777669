

import { ReactComponent as DefaultLogo } from '../../assets/defaultLogo.svg';
import { ReactComponent as Discord } from '../../assets/discord.svg';
import { ReactComponent as Ink } from '../../assets/ink.svg';
import { ReactComponent as Twitter } from '../../assets/twitter.svg';
import { LinkWithQuery } from '~/ui/utils/LinkWithQuery';
function Footer() {
	return (
		<footer className="defined-footer-bg sticky top-[100%] px-5 py-10 md:px-10 md:py-14">
			<div className=" h-full max-w-[1440px] gap-5 px-5 2xl:p-0" style={{ margin: '0 auto' }}>
				<div className="defined-footer-top flex">
					<LinkWithQuery className="default-logo-tag-a marginBottom30" to="/">
						<DefaultLogo className="default-logo-size" />
					</LinkWithQuery>
					<div className="defined-footer-lists  flex">
						<LinkWithQuery className="default-link-tag-a marginBottom30" to="/">
							Blog
						</LinkWithQuery>
						<LinkWithQuery className="default-link-tag-a marginBottom30" to="/">
							Whitepaper
						</LinkWithQuery>
						<LinkWithQuery className="default-link-tag-a marginBottom30" to="/">
							Docs
						</LinkWithQuery>
						<LinkWithQuery className="default-link-tag-a marginBottom30" to="/">
							Twitter
						</LinkWithQuery>
						<LinkWithQuery className="default-link-tag-a marginBottom30" to="/">
							<Discord />
						</LinkWithQuery>
						<LinkWithQuery className="default-link-tag-a marginBottom30" to="/">
							<Ink />
						</LinkWithQuery>
						<LinkWithQuery className="default-link-tag-a marginBottom30" to="/">
							<Twitter />
						</LinkWithQuery>
					</div>
				</div>
				<div className="defined-footer-bottom flex">
					<p className="marginTop30">Copyright © 2022-2023 Mango.All Rights Reserved</p>
					<span className="flex">
						<LinkWithQuery className="marginTop30 default-footer-tag-a " to="/">
							Terms & Conditions
						</LinkWithQuery>
						<LinkWithQuery className="default-footer-tag-a marginTop30" to="/">
							Privacy Policy
						</LinkWithQuery>
					</span>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
