

import { isManNSName, useManNSEnabled } from '@mango/core';
import { useLatestManSystemState, useManClient } from '@mango/dapp-kit';
import { type ManClient, type ManSystemStateSummary } from '@mango/man.js/client';
import {
	isValidTransactionDigest,
	isValidManAddress,
	isValidManObjectId,
	normalizeManObjectId,
} from '@mango/man.js/utils';
import { useQuery } from '@tanstack/react-query';

const isGenesisLibAddress = (value: string): boolean => /^(0x|0X)0{0,39}[12]$/.test(value);

type Results = { id: string; label: string; type: string }[];

const getResultsForTransaction = async (client: ManClient, query: string) => {
	if (!isValidTransactionDigest(query)) return null;
	const txdata = await client.getTransactionBlock({ digest: query });
	return [
		{
			id: txdata.digest,
			label: txdata.digest,
			type: 'transaction',
		},
	];
};

const getResultsForObject = async (client: ManClient, query: string) => {
	const normalized = normalizeManObjectId(query);
	if (!isValidManObjectId(normalized)) return null;

	const { data, error } = await client.getObject({ id: normalized });
	if (!data || error) return null;

	return [
		{
			id: data.objectId,
			label: data.objectId,
			type: 'object',
		},
	];
};

const getResultsForCheckpoint = async (client: ManClient, query: string) => {
	// Checkpoint digests have the same format as transaction digests:
	if (!isValidTransactionDigest(query)) return null;

	const { digest } = await client.getCheckpoint({ id: query });
	if (!digest) return null;

	return [
		{
			id: digest,
			label: digest,
			type: 'checkpoint',
		},
	];
};

const getResultsForAddress = async (client: ManClient, query: string, manNSEnabled: boolean) => {
	if (manNSEnabled && isManNSName(query)) {
		const resolved = await client.resolveNameServiceAddress({ name: query.toLowerCase() });
		if (!resolved) return null;
		return [
			{
				id: resolved,
				label: resolved,
				type: 'address',
			},
		];
	}

	const normalized = normalizeManObjectId(query);
	if (!isValidManAddress(normalized) || isGenesisLibAddress(normalized)) return null;

	const [from, to] = await Promise.all([
		client.queryTransactionBlocks({
			filter: { FromAddress: normalized },
			limit: 1,
		}),
		client.queryTransactionBlocks({
			filter: { ToAddress: normalized },
			limit: 1,
		}),
	]);

	if (!from.data?.length && !to.data?.length) return null;

	return [
		{
			id: normalized,
			label: normalized,
			type: 'address',
		},
	];
};

// Query for validator by pool id or man address.
const getResultsForValidatorByPoolIdOrManAddress = async (
	systemStateSummery: ManSystemStateSummary | null,
	query: string,
) => {
	const normalized = normalizeManObjectId(query);
	if ((!isValidManAddress(normalized) && !isValidManObjectId(normalized)) || !systemStateSummery)
		return null;

	// find validator by pool id or man address
	const validator = systemStateSummery.activeValidators?.find(
		({ stakingPoolId, suiAddress }) => stakingPoolId === normalized || suiAddress === query,
	);
	if (!validator) return null;

	return [
		{
			id: validator.suiAddress || validator.stakingPoolId,
			label: normalized,
			type: 'validator',
		},
	];
};

export function useSearch(query: string) {
	const client = useManClient();
	const { data: systemStateSummery } = useLatestManSystemState();
	const manNSEnabled = useManNSEnabled();

	return useQuery({
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: ['search', query],
		queryFn: async () => {
			const results = (
				await Promise.allSettled([
					getResultsForTransaction(client, query),
					getResultsForCheckpoint(client, query),
					getResultsForAddress(client, query, manNSEnabled),
					getResultsForObject(client, query),
					getResultsForValidatorByPoolIdOrManAddress(systemStateSummery || null, query),
				])
			).filter((r) => r.status === 'fulfilled' && r.value) as PromiseFulfilledResult<Results>[];

			return results.map(({ value }) => value).flat();
		},
		enabled: !!query,
		cacheTime: 10000,
	});
}
