
import { DisplayFieldsResponse, ManObjectResponse } from '@mango/man.js/client';
import { hasDisplayData } from '../hasDisplayData';

export function getObjectDisplayLookup(objects: ManObjectResponse[] = []) {
	const lookup: Map<string, DisplayFieldsResponse> = new Map();
	return objects?.filter(hasDisplayData).reduce((acc, curr) => {
		if (curr.data?.objectId) {
			acc.set(curr.data.objectId, curr.data.display as DisplayFieldsResponse);
		}
		return acc;
	}, lookup);
}
