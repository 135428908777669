

import { useQuery } from '@tanstack/react-query';
import { chunkArray } from '../utils/chunkArray';
import { ManObjectDataOptions } from '@mango/man.js/src/client';
import { useManClient } from '@mango/dapp-kit';

export function useMultiGetObjects(
	ids: string[],
	options: ManObjectDataOptions,
	queryOptions?: { keepPreviousData?: boolean },
) {
	const client = useManClient();
	return useQuery({
		queryKey: ['multiGetObjects', ids],
		queryFn: async () => {
			const responses = await Promise.all(
				chunkArray(ids, 50).map((chunk) =>
					client.multiGetObjects({
						ids: chunk,
						options,
					}),
				),
			);
			return responses.flat();
		},
		enabled: !!ids?.length,
		...queryOptions,
	});
}
