

import {
	type MoveCallManTransaction,
	type ManArgument,
	type ManMovePackage,
} from '@mango/man.js/client';
import { Text } from '@mango/ui';
import { type ReactNode } from 'react';

import { flattenManArguments } from './utils';
import { ErrorBoundary } from '~/components/error-boundary/ErrorBoundary';
import { ObjectLink } from '~/ui/InternalLink';

export interface TransactionProps<T> {
	type: string;
	data: T;
}

function TransactionContent({ children }: { children?: ReactNode }) {
	return (
		<Text variant="pBody/normal" color="steel-dark">
			{children}
		</Text>
	);
}

function ArrayArgument({ data }: TransactionProps<(ManArgument | ManArgument[])[] | undefined>) {
	return (
		<TransactionContent>
			{data && (
				<span className="break-all">
					<Text variant="pBody/medium" color="defined-label-text">
						({flattenManArguments(data)})
					</Text>
				</span>
			)}
		</TransactionContent>
	);
}

function MoveCall({ data }: TransactionProps<MoveCallManTransaction>) {
	const {
		module: moduleName,
		package: movePackage,
		function: func,
		arguments: args,
		type_arguments: typeArgs,
	} = data;
	let module = moduleName.replace(/Sui/g, 'Man').replace(/sui/g, 'man');
	return (
		<TransactionContent>
			<Text variant="pBody/medium" color="defined-label-text">
				(package: <ObjectLink objectId={movePackage} />, module:{' '}
				<ObjectLink objectId={`${movePackage}?module=${module}`} label={`'${module}'`} />, function:{' '}
				<span className="break-all text-hero-dark">{func}</span>
				{args && <span className="break-all">, arguments: [{flattenManArguments(args!)}]</span>}
				{typeArgs && <span className="break-all">, type_arguments: [{typeArgs.join(', ')}]</span>}
			</Text>
		</TransactionContent>
	);
}

export function Transaction({
	type,
	data,
}: TransactionProps<(ManArgument | ManArgument[])[] | MoveCallManTransaction | ManMovePackage>) {
	if (type === 'MoveCall') {
		return (
			<ErrorBoundary>
				<MoveCall type={type} data={data as MoveCallManTransaction} />
			</ErrorBoundary>
		);
	}

	return (
		<ErrorBoundary>
			<ArrayArgument
				type={type}
				data={type !== 'Publish' ? (data as (ManArgument | ManArgument[])[]) : undefined}
			/>
		</ErrorBoundary>
	);
}
