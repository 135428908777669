
import { SVGProps } from 'react';
const SvgInfo16 = (props: SVGProps<SVGSVGElement>) => (
	// <svg
	// 	xmlns="http://www.w3.org/2000/svg"
	// 	width="1em"
	// 	height="1em"
	// 	fill="none"
	// 	viewBox="0 0 16 16"
	// 	{...props}
	// >
	// 	<path
	// 		stroke="currentColor"
	// 		strokeLinecap="round"
	// 		strokeLinejoin="round"
	// 		strokeWidth={1.5}
	// 		d="M8 13.333A5.333 5.333 0 1 0 8 2.667a5.333 5.333 0 0 0 0 10.666ZM8 10.667V8M8 5.333h.005"
	// 	/>
	// </svg>

<svg xmlns="http://www.w3.org/2000/svg"  fill="none" version="1.1" width="1em" height="1em" viewBox="0 0 12 12" {...props}><g><path d="M6,0C9.31371,0,12,2.68629,12,6C12,9.31371,9.31371,12,6,12C2.68629,12,0,9.31371,0,6C0,2.68629,2.68629,0,6,0ZM6,1.00029C3.23806,0.999332,0.99856,3.23806,0.99856,6C0.99856,8.76194,3.23806,11.0007,6,10.9997C8.76059,10.9988,10.998,8.76059,10.998,6C10.998,3.23941,8.76059,1.00124,6,1.00029ZM5.96486,3.83743L6.04114,3.84429C6.23829,3.88029,6.38657,4.05257,6.38657,4.26L6.38657,8.36228L7.38514,8.36228C7.61829,8.36228,7.80686,8.55086,7.80686,8.784L7.8,8.86028C7.76352,9.06082,7.5889,9.20657,7.38514,9.20657L4.54457,9.20657C4.31133,9.20657,4.12238,9.01724,4.12286,8.784L4.12971,8.70857C4.1662,8.50804,4.34081,8.36228,4.54457,8.36228L5.54314,8.36228L5.54314,4.68171L4.84029,4.68171C4.60705,4.68219,4.41771,4.49324,4.41771,4.26L4.42457,4.18371C4.46133,3.9836,4.63585,3.83829,4.83943,3.83829L5.96571,3.83829L5.96486,3.83743ZM5.97171,2.57143C6.20841,2.57143,6.40029,2.76331,6.40029,3C6.40029,3.23669,6.20841,3.42857,5.97171,3.42857C5.73502,3.42857,5.54314,3.23669,5.54314,3C5.54314,2.76331,5.73502,2.57143,5.97171,2.57143Z" fill="currentColor" /></g></svg>
);
export default SvgInfo16;
