
import { getRefGasPrice } from '@mango/core';
import { useLatestManSystemState } from '@mango/dapp-kit';
import { Heading, Text } from '@mango/ui';
import { useMemo } from 'react';

import { Card } from '~/ui/Card';
import { RingChart, RingChartLegend } from '~/ui/RingChart';

export function ValidatorStatus() {
	const { data } = useLatestManSystemState();

	const nextRefGasPrice = useMemo(
		() => getRefGasPrice(data?.activeValidators),
		[data?.activeValidators],
	);

	if (!data) return null;

	const nextEpoch = Number(data.epoch || 0) + 1;

	const chartData = [
		{
			value: data.activeValidators.length,
			label: 'Active',
			gradient: {
				deg: 315,
				values: [
					{ percent: 0, color: '#46ADB3' },
					{ percent: 100, color: 'rgb(70 173 179)' },
				],
			},
		},
		{
			value: Number(data.pendingActiveValidatorsSize ?? 0),
			label: 'New',
			color: '#F2BD24',
		},
		{
			value: data.atRiskValidators.length,
			label: 'At Risk',
			color: '#FF794B',
		},
	];

	return (
		<Card spacing="lg" bg="definedBlack" rounded="2xl">
			<div className="mb-2">
				<Heading color="steel-darker" variant="heading3/semibold">
					{`Validators in Epoch ${nextEpoch}`}
				</Heading>
			</div>
			<div className="flex items-center gap-5">
				<div className="min-h-[96px] min-w-[96px]">
					<RingChart data={chartData} />
				</div>
				<div className="self-start">
					<RingChartLegend data={chartData} title="" />
				</div>
			</div>

			<div className="defined-epoch-bg-grey defined-my-bg-grey mt-8 flex items-center justify-between rounded-lg  px-3 py-2">
				<div>
					<Text variant="pSubtitle/semibold" color="steel-darker">
						Estimated Next Epoch
					</Text>
					<Text variant="pSubtitle/semibold" color="steel-darker">
						Reference Gas Price
					</Text>
				</div>
				<div className="text-right">
					<Heading variant="heading4/normal" color="defined-epoch-text">
						{nextRefGasPrice.toString()}
					</Heading>
					<Text variant="pBody/normal" color="defined-epoch-text">
						CELL
					</Text>
				</div>
			</div>
		</Card>
	);
}
