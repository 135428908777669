
import { SVGProps } from 'react';
const SvgWarning16 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 16 16"
		{...props}
	>
		<path
			fill="currentColor"
			d="M5.78 14.937h3.91c.832 0 1.314-.286 1.77-.794l2.432-2.736c.457-.514.578-.85.578-1.549v-2.78c0-.698-.153-1.003-.622-1.549L11.46 2.8C11.01 2.287 10.52 2 9.69 2H5.78c-.832 0-1.314.292-1.771.8L1.57 5.53C1.114 6.05 1 6.38 1 7.077v2.78c0 .699.114 1.035.571 1.55l2.438 2.735c.457.508.94.793 1.77.793Zm.28-1.39c-.579 0-.782-.14-1.105-.496L2.828 10.69c-.247-.286-.305-.463-.305-.933V7.18c0-.464.058-.648.305-.927l2.127-2.368c.323-.355.526-.495 1.104-.495h3.345c.584 0 .787.14 1.111.495l2.127 2.368c.247.28.304.463.304.927v2.577c0 .47-.057.647-.304.933l-2.127 2.361c-.324.356-.527.495-1.11.495H6.058ZM7.74 9.458c.368 0 .59-.204.603-.604l.095-2.881c.02-.4-.285-.686-.71-.686-.426 0-.718.28-.699.68l.09 2.894c.012.387.234.597.621.597Zm0 2.132c.432 0 .781-.292.781-.723 0-.42-.343-.724-.78-.724-.445 0-.794.305-.794.724 0 .425.355.723.793.723Z"
		/>
	</svg>
);
export default SvgWarning16;
