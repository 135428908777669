

import { useLatestManSystemState, useRpcApiVersion } from '@mango/dapp-kit';
import { useContext } from 'react';

import { NetworkContext } from '../../context';
import { Network } from '../../utils/api/DefaultRpcClient';
import { NetworkSelect, type NetworkOption } from '~/ui/header/NetworkSelect';
import { ampli } from '~/utils/analytics/ampli';

export default function WrappedNetworkSelect() {
	const [network, setNetwork] = useContext(NetworkContext);
	const { data } = useLatestManSystemState();
	const { data: binaryVersion } = useRpcApiVersion({});
	// Mainnet
	const networks = [
		{ id: Network.MAINNET, label: 'Demonet' },
		{ id: Network.LOCAL, label: 'Local' },
	].filter(Boolean) as NetworkOption[];

	return (
		<NetworkSelect
			value={network}
			onChange={(networkId) => {
				ampli.switchedNetwork({ toNetwork: networkId });
				setNetwork(networkId.replace(/MAINNET/g, 'DEMONET'));
			}}
			networks={networks}
			version={data?.protocolVersion}
			binaryVersion={binaryVersion}
		/>
	);
}
