

import { formatAddress, formatDigest } from './format.js';
import {
	isValidManAddress,
	isValidManObjectId,
	isValidTransactionDigest,
	normalizeStructTag,
	normalizeManAddress,
	normalizeManObjectId,
	parseStructTag,
	MAN_ADDRESS_LENGTH,
} from './man-types';

export { fromB64, toB64 } from '@mango/bcs';
export { is, assert } from 'superstruct';

export {
	formatAddress,
	formatDigest,
	isValidManAddress,
	isValidManObjectId,
	isValidTransactionDigest,
	normalizeStructTag,
	normalizeManAddress,
	normalizeManObjectId,
	parseStructTag,
	MAN_ADDRESS_LENGTH,
};

export const MAN_DECIMALS = 9;
export const MIST_PER_MAN = BigInt(1000000000);

export const MOVE_STDLIB_ADDRESS = '0x1';
export const MAN_FRAMEWORK_ADDRESS = '0x2';
export const MAN_SYSTEM_ADDRESS = '0x3';
export const MAN_CLOCK_OBJECT_ID = normalizeManObjectId('0x6');
export const MAN_SYSTEM_MODULE_NAME = 'man_system';
export const MAN_TYPE_ARG = `${MAN_FRAMEWORK_ADDRESS}::man::MAN`;
export const MAN_SYSTEM_STATE_OBJECT_ID: string = normalizeManObjectId('0x5');
