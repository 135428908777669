

/**
 *  ######################################
 *  ### DO NOT EDIT THIS FILE DIRECTLY ###
 *  ######################################
 *
 * This file is generated from:
 * /crates/man-open-rpc/spec/openrpc.json
 */

import type { GetLatestManSystemStateParams } from '@mango/man.js/client';
import type { UseManClientQueryOptions } from '../useManClientQuery';
import { useManClientQuery } from '../useManClientQuery';

export function useLatestManSystemState(
	params: GetLatestManSystemStateParams = {},
	options?: UseManClientQueryOptions<'getLatestManSystemState'>,
) {
	return useManClientQuery(
		{
			method: 'getLatestManSystemState',
			params,
		},
		options,
	);
}
