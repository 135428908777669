
import { SVGProps } from 'react';
const SvgDot12 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 12 12"
		{...props}
	>
		<circle cx={6} cy={6} r={3} fill="currentColor" />
	</svg>
);
export default SvgDot12;
